
import styles from "./coverage.module.scss";
import titleGrayBg from "../../assets/images/ai27_small_gray_bg2.png";
import ReactPlayer from "react-player";
// import mapMovilBg from "https://ai27-landing-page-assets.s3.amazonaws.com/Map_mobile.gif";
import mapBg from '../../assets/videos/map_desktop.mp4';
import mapMobileBg from '../../assets/videos/map_mobile.mp4';


const Coverage = ({ languageData }: { languageData: { [k: string]: string } }) => {

	return ( 
		<div className={styles.container_coverage}>
			<div className={styles.title_mobile}>
				<img src={titleGrayBg} alt="titleGrayBg" />
				<span className="main_title">{languageData.title1} </span><span className="main_title"> {languageData.title2}</span>
			</div>
			<video controls={false} loop autoPlay playsInline src={mapBg} muted className={styles.map}/>
			<video controls={false} loop autoPlay playsInline src={mapMobileBg} muted className={styles.short_map}/>
			<div className={styles.wrapper}>
				<div className={styles.title}>
					<img src={titleGrayBg} alt="titleGrayBg" />
					<span className="main_title">{languageData.title1} </span><span className="main_title"> {languageData.title2}</span>
				</div>
				<div className={styles.cart}>
					<span className={styles.cart_title}>{languageData.cart1Title}</span>
					<span className={`${styles.cart_body} single_content`}>{languageData.cart1Content}</span>
				</div>
				<div className={styles.cart}>
					<span className={styles.cart_title}>{languageData.cart2Title}</span>
					<span className={`${styles.cart_body} single_content`}>{languageData.cart2Content}</span>
				</div>
				<div className={styles.cart}>
					<span className={styles.cart_title}>{languageData.cart3Title}</span>
					<span className={`${styles.cart_body} single_content`}>{languageData.cart3Content}</span>
				</div>
				<div className={styles.cart}>
					<span className={styles.cart_title}>{languageData.cart4Title}</span>
					<span className={`${styles.cart_body} single_content`}>{languageData.cart4Content}</span>
				</div>
			</div>
		</div>
	);
}
export default Coverage;