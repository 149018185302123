import {
	useEffect,
	useRef
} from 'react';
import H from '@here/maps-api-for-javascript';
import './map.style.scss';

const HereMap = ({
	apikey
}: { apikey: string }) => {
	const mapRef = useRef(null);
	const map = useRef(null);
	const platform = useRef(null);

	const tileProvider = new H.map.provider.ImageTileProvider({
		getURL: (column, row, zoom) => (
			`https://maps.hereapi.com/v3/base/mc/${zoom}/${column}/${row}/png8?style=explore.day&size=256&apiKey=${apikey}`
		),
	});
	const overlayLayer = new H.map.layer.TileLayer(tileProvider, {});
	const browserWidth = window.innerWidth;
	useEffect(() => {
		if (!map.current) {
			// @ts-expect-error platform typing
			platform.current = new H.service.Platform({
				apikey
			});

			const centerCoords = {
				lat: 19.505079063395128,
				lng: -99.17806837506355,
			};

			const ai27Coords = {
				lat: 19.504179063395128,
				lng: -99.17826837506355,
			};

			const newMap = new H.Map(
				// @ts-expect-error platform typing
				mapRef.current,
				overlayLayer, {
				zoom: 17,
				center: browserWidth > 600 ? ai27Coords : centerCoords,
			}
			);

			new H.mapevents.Behavior(
				new H.mapevents.MapEvents(newMap)
			);

			const customMarker = `<svg width="40" height="53" viewBox="0 0 60 73" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path id="Vector" d="M30 37.1975C32.0625 37.1975 33.8288 36.4684 35.2988 35.0102C36.7663 33.5546 37.5 31.8038 37.5 29.758C37.5 27.7121 36.7663 25.9601 35.2988 24.502C33.8288 23.0463 32.0625 22.3185 30 22.3185C27.9375 22.3185 26.1725 23.0463 24.705 24.502C23.235 25.9601 22.5 27.7121 22.5 29.758C22.5 31.8038 23.235 33.5546 24.705 35.0102C26.1725 36.4684 27.9375 37.1975 30 37.1975ZM30 73C29.5 73 29 72.907 28.5 72.721C28 72.535 27.5625 72.2871 27.1875 71.9771C18.0625 63.9796 11.25 56.5562 6.75 49.707C2.25 42.8552 0 36.4535 0 30.5019C0 21.2025 3.01625 13.7941 9.04875 8.27643C15.0788 2.75881 22.0625 0 30 0C37.9375 0 44.9212 2.75881 50.9512 8.27643C56.9837 13.7941 60 21.2025 60 30.5019C60 36.4535 57.75 42.8552 53.25 49.707C48.75 56.5562 41.9375 63.9796 32.8125 71.9771C32.4375 72.2871 32 72.535 31.5 72.721C31 72.907 30.5 73 30 73Z" fill="url(#paint0_linear_1583_6704)" />
				<defs>
					<linearGradient id="paint0_linear_1583_6704" x1="2.79397e-07" y1="36.5" x2="60" y2="36.5" gradientUnits="userSpaceOnUse">
						<stop stop-color="#1C06F4" />
						<stop offset="1" stop-color="#E600FF" />
					</linearGradient>
				</defs>
			</svg>`;

			// @ts-expect-error platform typing
			map.current = newMap;

			const icon = new H.map.Icon(customMarker);
			const marker = new H.map.Marker(ai27Coords, { icon: icon } as H.map.Marker.Options);
			const bubble = `<div class="wrapper-bubble">
				<h2>Tecnoparque</h2>
				<span>3,938.1 km Eje 5 Norte 990, Santa Bárbara, 02230 Azcapotzalco, CDMX, México</span>
			</div>`;
			marker.setData(bubble);

			// @ts-expect-error platform typing
			map.current.addObject(marker);

			if (map.current) {
				const newBubble = new H.ui.InfoBubble({
					lat: 19.504579063395128,
					lng: -99.17826837506355,
				}, {
					content: bubble,
				});
				const ui = H.ui.UI.createDefault(map.current, overlayLayer);
				ui.addBubble(newBubble);
				ui.removeControl('mapsettings');
				ui.removeControl('scalebar');
				ui.removeControl('zoom');
				ui.getBubbles().forEach((bub) => bub.open());
			}
		}
	}, [apikey]);

	return <div style={{width: "100%", height: "100%"}} ref={mapRef}/>;
}

export default HereMap;