import { useEffect, useState } from "react";
import styles from "./home.module.scss";
import homeNameImg from "../../assets/images/home-name-bg.png";
import icon1 from "../../assets/images/icon1.png";
import icon2 from "../../assets/images/icon2.png";
import icon3 from "../../assets/images/icon3.png";
import footerEsImg from "../../assets/images/home_footer_es.png";
import footerEnImg from "../../assets/images/home_footer_en.png";
import Header from "../header";

interface homeProps {
	scrollToTools: () => void;
	scrollToProducts: () => void;
	scrollToCoverage: () => void;
	scrollToContact: () => void;
	languageDataHeader: { [k: string]: string };
	languageDataHome: { [k: string]: string };
	setLanguage: (language: string) => void;
	language: string;
}

const Home = ({
	scrollToTools,
	scrollToProducts,
	scrollToCoverage,
	scrollToContact,
	languageDataHeader,
	languageDataHome,
	setLanguage,
	language,
}: homeProps) => {
	const [currentText, setCurrentText] = useState<string>('');
	const [textIndex, setTextIndex] = useState<number>(0);

	const texts = [
		languageDataHome.carouselText1,
		languageDataHome.carouselText2,
		languageDataHome.carouselText3,
		languageDataHome.carouselText4,
		languageDataHome.carouselText5,
	];

	const [opacity, setOpacity] = useState<number>(1); // Mantiene la opacidad en 1

	useEffect(() => {
		const typingEffect = setTimeout(() => {
			if (currentText.length < texts[textIndex].length) {
				// Agrega la siguiente letra
				setCurrentText((prev) => prev + texts[textIndex].charAt(currentText.length));
			} else {
				// Espera 2 segundos antes de cambiar al siguiente texto
				setOpacity(1); // Mantiene la opacidad al completar el texto
				setTimeout(() => {
					setTextIndex((textIndex + 1) % texts.length);
					setCurrentText(''); // Limpia el texto para la siguiente frase
				}, 2000); // Tiempo de espera de 2 segundos antes de cambiar la frase
			}
		}, currentText.length < texts[textIndex].length ? 50 : 0); // Velocidad de escritura

		return () => clearTimeout(typingEffect);
	}, [currentText, textIndex]);

	return (
		<div className={styles.container_home}>
			<div className={styles.container_home_bg}>
				<Header
					scrollToTools={scrollToTools}
					scrollToProducts={scrollToProducts}
					scrollToCoverage={scrollToCoverage}
					scrollToContact={scrollToContact}
					languageData={languageDataHeader}
					setLanguage={setLanguage}
					language={language}
				/>
				<div className={styles.container_home_body}>
					{/* <div className={styles.container_name_bg}>
					<img
						alt="home-name-bg"
						src={homeNameImg}
						className={styles.home_name_bg}
					/>
				</div> */}
					{/* Aumenta la altura del contenedor aquí */}
					<div className={styles.home_title} style={{ height: '80px', overflow: 'hidden' }}>
						<label style={{
							opacity,
							transition: "opacity 0.5s ease",
							textDecoration: 'none', // Eliminar subrayado
						}}>
							{currentText}
						</label>
					</div>
					<div className={styles.home_subtitle_container}>
						<div className={styles.home_subtitle}>
							<div>
								<img
									className={styles.icon1}
									alt="icon1"
									src={icon1}
								/>
							</div>
							<span>+25</span>
							<span>{languageDataHome.icon1Text}</span>
						</div>
						<div className={styles.home_subtitle}>
							<div>
								<img
									className={styles.icon2}
									alt="icon2"
									src={icon2}
								/>
							</div>
							<span>+11,700</span>
							<span>{languageDataHome.icon2Text}</span>
						</div>
						<div className={styles.home_subtitle}>
							<div>
								<img
									className={styles.icon3}
									alt="icon3"
									src={icon3}
								/>
							</div>
							<span>+250</span>
							<span>{languageDataHome.icon3Text}</span>
						</div>
					</div>
					<div className={styles.home_footer}>
						{/* <img
						alt="home-footer"
						src={language === 'es-ES' ? footerEsImg : footerEnImg}
						width={window.innerWidth / 4}
					/> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
