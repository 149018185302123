import styles from "./customers.module.scss";
import ainsuranceLogo from "../../assets/images/ainsurance_opacy_logo.png";
import gnpLogo from "../../assets/images/gnp_logo.png";
import zuruLogo from "../../assets/images/zuru_logo.png";
import chubbLogo from "../../assets/images/chubb_logo.png";
import berkleyLogo from "../../assets/images/berkley_logo.png";
import zurichLogo from "../../assets/images/zurich_logo.png";
import johnsonLogo from "../../assets/images/johnson_logo.png";
import hasbroLogo from "../../assets/images/hasbro_logo.png";
import mattelLogo from "../../assets/images/mattel_logo.png";
import levisLogo from "../../assets/images/levis_logo.png";
import mercadoLibreLogo from "../../assets/images/mercadoLibre_logo.png";
import amazonLogo from "../../assets/images/amazon_logo.png";
import nestleLogo from "../../assets/images/nestle_logo.png";
import pfizerLogo from "../../assets/images/pfizer_logo.png";
import herdezLogo from "../../assets/images/herdez_logo.png";
import mondelezLogo from "../../assets/images/mondelez_logo.png";
import comexLogo from "../../assets/images/comex_logo.png";
import penolesLogo from "../../assets/images/penoles_logo2.png";
import titleWhiteBg from "../../assets/images/ai27_small_white_bg.png";
import titleGrayBg from "../../assets/images/ai27_small_gray_bg.png";


const Customers = ({ languageData, scrollToContact, language }: { languageData: { [k: string]: string }, scrollToContact: () => void, language: string }) => {
	return (
		<div className={styles.container_customers}>
			<div className={styles.row}>
				<div className={styles.cart}>
					<div className={styles.cart_header}>
						<div className={styles.cart_title}>
							<img src={titleWhiteBg} alt="titleWhiteBg" />
							<span className="main_title">{languageData.cart1Title1} </span> <span className="main_title">{languageData.cart1Title2}</span>
						</div>
						<img className={styles.cart_header_logo} src={ainsuranceLogo} alt="secur-logo" />
					</div>
					<span className={`${styles.cart_body} single_content`}>{languageData.cart1Content}</span>
					<div className={styles.cart_footer}>
						<button className={styles.first_btn}>{languageData.cart1Button1}</button>
						<button onClick={scrollToContact} className={styles.second_btn}>{languageData.cart1Button2}</button>
					</div>
				</div>
				<div className={`${styles.cart} ${styles.cart_insurance}`}>
					<div className={styles.img_grapper}>
						<img height={70} src={gnpLogo} alt="gnp-logo" />
						<img height={80} src={zuruLogo} alt="zuru-logo" />
					</div>
					<div className={styles.img_grapper}>
						<img height={60} src={chubbLogo} alt="chubb-logo" />
						<img height={50} src={berkleyLogo} alt="berkley-logo" />
					</div>
					<div className={styles.img_grapper}>
						<img height={60} src={zurichLogo} alt="zurich-logo" />
					</div>
				</div>
			</div>
			<div className={`${styles.row} ${styles.coverage_background}`}>
				<div className={styles.cart}>
					<div className={`${styles.img_grapper} ${styles.img_medium}`}>
						<img height={80} src={herdezLogo} alt="herdez-logo" />
						<img height={80} src={hasbroLogo} alt="hasbro-logo" />
						<img height={80} src={mattelLogo} alt="mattel-logo" />
						<img height={90} src={penolesLogo} alt="penoles-logo" />

					</div>
					<div className={`${styles.img_grapper} ${styles.img_small}`}>
						<img height={35} src={mercadoLibreLogo} alt="mercadoLibre-logo" />
						<img height={35} src={amazonLogo} alt="amazon-logo" />
						<img height={35} src={nestleLogo} alt="nrestle-logo" />
						<img height={45} src={pfizerLogo} alt="pfizer-logo" />
					</div>
					<div className={`${styles.img_grapper} ${styles.img_big}`}>
						<img height={80} src={johnsonLogo} alt="johnson-logo" />
						<img height={30} src={mondelezLogo} alt="mondelez-logo" />
						<img height={38} src={comexLogo} alt="pemex-logo" />
						<img height={40} src={levisLogo} alt="levis-logo" />
					</div>
				</div>
				<div className={styles.cart}>
					<div className={styles.cart_title}>
						<img src={titleGrayBg} className={language === 'en' ? styles.title_bg_en : styles.title_bg} alt="titleGrayBg" />
						<span className="main_title">{languageData.cart2Title1} </span> <span className="main_title">{languageData.cart2Title2}</span>
					</div>
					<div className={`${styles.cart_body} single_content`}>{languageData.cart2Content}</div>
				</div>
			</div>
		</div>
	);
}
export default Customers;