
import { useEffect, useState } from "react";
import Ais from "../../assets/images/logoHd.png";
import mobileMenuIcon from "../../assets/images/mobileMenuIcon.png";
import styles from "./header.module.scss";

interface HeaderProps {
  scrollToTools: () => void;
  scrollToProducts: () => void;
  scrollToCoverage: () => void;
  scrollToContact: () => void;
  languageData: { [k: string]: string };
  setLanguage: (language: string) => void;
  language: string;
}

const Header = ({
  scrollToTools,
  scrollToProducts,
  scrollToCoverage,
  scrollToContact,
  languageData,
  setLanguage,
  language,
}: HeaderProps) => {

  const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);
  const [isSubmenuHiden, setIsSubmenuHiden] = useState(true);
  const handleHideMenu = () => setIsSubmenuHiden(!isSubmenuHiden);
  const handleHideMobileMenu = () => setIsMobileMenuShown(!isMobileMenuShown);

  useEffect(() => {
    const onClickSomething = (e: any) => {
      if (!(document && document.getElementById('submenuContainer')?.contains(e.target))) {
        setIsSubmenuHiden(true);
      }

      if (!(document && document.getElementById('mobileMenu')?.contains(e.target))) {
        setIsMobileMenuShown(false);
      }
    }
    window.addEventListener('click', onClickSomething);

    return () => {
      window.removeEventListener('click', onClickSomething);
    }
  }, []);

  return (
    <>
      <div className={styles.header_container}>
        <div className={styles.long_menu}>
          <span >
            <img src={Ais} alt="ais-logo" />
          </span>
          <span onClick={() => setLanguage('es')} className={`${styles.menu} ${language === 'es' && styles.menu_is_active}`}>
            {languageData.spa}
          </span>
          <span onClick={() => setLanguage('en')} className={`${styles.menu} ${language === 'en' && styles.menu_is_active}`}>
            {languageData.eng}
          </span>
          <span className={styles.menu}>
            {languageData.home}
          </span>
          <div className={styles.menu} onClick={handleHideMenu} id="submenuContainer">
            <button onClick={scrollToTools} className={styles.menu_button}>{languageData.tools}</button>
            {false && !isSubmenuHiden && <div className={styles.submenu_container}>
              <span onClick={scrollToTools} className={styles.submenu}>ELIAS</span>
              <span onClick={scrollToTools} className={styles.submenu}>Algoritmo de Riesgo</span>
              <span onClick={scrollToTools} className={styles.submenu}>Follow/Protector</span>
              <span onClick={scrollToTools} className={styles.submenu}>RPA (Robotic Process Automation)</span>
            </div>}
          </div>
          <span onClick={scrollToProducts} className={styles.menu}>
            {languageData.products}
          </span>
          <span onClick={scrollToCoverage} className={styles.menu}>
            {languageData.coverage}
          </span>
          <span onClick={scrollToContact} className={styles.menu}>
            {languageData.contact}
          </span>
        </div>
        <div className={styles.short_menu}>
          <div id="mobileMenu" className={styles.mobile_menu_container}>
            <img onClick={handleHideMobileMenu} className={styles.icon_mobile} src={mobileMenuIcon} alt="mobileMenuIcon" />
            {isMobileMenuShown && <div onClick={handleHideMobileMenu} className={styles.mobile_menu_wrapper}>
              <span>{languageData.home}</span>
              <span onClick={scrollToTools}>{languageData.tools}</span>
              <span onClick={scrollToProducts}>{languageData.products}</span>
              <span onClick={scrollToCoverage}>{languageData.coverage}</span>
              <span onClick={scrollToContact}>{languageData.contact}</span>
            </div>}
          </div>
          <img className={styles.logo_mobile} src={Ais} alt="ais-logo" />
          <div className={styles.language_container}>
            <span onClick={() => setLanguage('es')} className={`${styles.menu_mobile} ${language.includes('es') && styles.menu_mobile_is_active}`}>
              {languageData.spa}
            </span>
            <span onClick={() => setLanguage('en')} className={`${styles.menu_mobile} ${language.includes('en') && styles.menu_mobile_is_active}`}>
              {languageData.eng}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;